import { useEffect, useRef, useState } from "react";
import * as Gmsh from '../pkg/mesh/emgmsh'
import * as Emgmsh from '../pkg/mesh/emgmsh-types'
import { Model } from "../App";

export const useGmsh = () => {
    const [gmshReady, setReady] = useState<boolean>(false);

    const module = useRef<Emgmsh.MainModule | null>(null);

    useEffect(() => {
        Gmsh.default().then((gmsh: Emgmsh.MainModule) => {
            module.current = gmsh;
            setReady(true);
        });
    },[]);

    const generateMesh = (m: Model, meshSizeFactor: number) => {
        if(module.current === null) return{ svg: "", json: "", uuidToNodes: null};
        const result = module.current.mesh_json(m.toGeometryJSON(), meshSizeFactor);
        const mesh = result.mesh;
        const svg = module.current.mesh_to_svg(mesh);
        const json = module.current.mesh_to_json(mesh);
        const res = {
            svg,
            uuidToNodes: new Map<string, Array<number>>(Object.entries(JSON.parse(module.current.uuid_map_to_json(result.uuid_to_nodes)))),
            json
        };
        mesh.delete();
        result.delete();
        return res;
    };

    return [gmshReady, generateMesh] as const;
};