import { v4 as uuid } from "uuid";

export type BoundaryConditionKind = 
    {type: "fixed", data: {u?: number, v?: number}} |
    {type: "force", data: {u?: number, v?: number}};

export interface BoundaryConditionProps{
    id?: string;
    kind: BoundaryConditionKind;
    geometry?: string;
};

export class BoundaryCondition
{
    id_: string;
    kind_: BoundaryConditionKind;
    geometry_: string;

    constructor(props: BoundaryConditionProps){
        if(props.id === undefined){
            this.id_ = uuid();
        } else {
            this.id_ = props.id;
        }

        this.kind_ = props.kind;
        this.geometry_ = props.geometry === undefined ? "" : props.geometry;
    }

    get props(): BoundaryConditionProps{
        return {
            id: this.id_,
            kind: this.kind_,
            geometry: this.geometry_
        }
    }
}