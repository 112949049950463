import {v4 as uuid} from 'uuid';

export type EdgeGeometry =
    { type: "straight", data: {}} | 
    { type: "arc", data: {center: string}};

export interface EdgeProps{
    start: string;
    end: string;
    id?: string;
    isSelected?: boolean;
    isHilighted?: boolean;
    geometry: EdgeGeometry;

};

export class Edge{
    _start: string;
    _end: string;
    _id: string;
    _isSelected: boolean;
    _isHilighted: boolean;
    _geometry: EdgeGeometry;

    constructor(props: EdgeProps){
        if(props.id !== undefined){
            this._id = props.id;
        } else {
            this._id = uuid();
        }

        this._start = props.start;
        this._end = props.end;

        if(props.isSelected !== undefined){
            this._isSelected = props.isSelected;
        } else {
            this._isSelected = false;
        }

        if(props.isHilighted !== undefined){
            this._isHilighted = props.isHilighted;
        } else {
            this._isHilighted = false;
        }

        this._geometry = props.geometry;
    }

    get start(){
        return this._start;
    }

    get end(){
        return this._end;
    }

    get id(){
        return this._id;
    }

    get props() : EdgeProps{
        return {
            start: this._start,
            end: this._end,
            id: this._id,
            isSelected: this._isSelected,
            isHilighted: this._isHilighted,
            geometry: this._geometry
        }
    }
};