import { Loop as GeometricLoop } from "../model/loop";
import { useEffect, useState } from "react";
import { Badge, Box, Button, Center, HStack, NumberDecrementStepper, NumberIncrementStepper, NumberInput, NumberInputField, NumberInputStepper, Text } from "@chakra-ui/react"

export interface LoopProps{
    loop: GeometricLoop,
    remove: (e: GeometricLoop) => void
    addSelected: (id: string) => void
    clearSelected: () => void
    addHilighted: (id: string) => void
    clearHilighted: () => void
}

export const Loop = ({
        loop,
        remove,
        addSelected,
        clearSelected,
        addHilighted,
        clearHilighted} : LoopProps) => {
    return (
        <Box
         width="100%"
        >
            <HStack>
                <Badge size="xl" flex="1"><Center>{loop.id.slice(0,5)}</Center></Badge>
                <Box flex="5" overflowX="scroll">
                    {loop._edges.map((e: string) => {
                        return <Badge>{e.slice(0, 5)}</Badge>
                    })}
                </Box>
                <Button onClick={() => remove(loop)} flex="1">Remove</Button>
            </HStack>
        </Box>
    );
}