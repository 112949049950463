import { useEffect, useState } from "react";
import { BoundaryCondition, BoundaryConditionProps } from "../model/boundarycondition";
import { Badge, Box, Button, Center, HStack, VStack, Radio, RadioGroup, Checkbox, NumberInput, NumberInputField, NumberInputStepper, NumberDecrementStepper, NumberIncrementStepper } from "@chakra-ui/react"

export interface BoundaryConditionComponentProps {
    bc: BoundaryCondition,
    removeBoundaryCondition: (arg0: BoundaryCondition) => void,
    updateBoundaryCondition: (arg0: BoundaryCondition, arg1: BoundaryConditionProps) => void
    isSelected: boolean,
    addSelected: (id: string) => void
    clearSelected: () => void
    isHilighted: boolean
    addHilighted: (id: string) => void
    clearHilighted: () => void
    startBcGeometrySelection?: (id: string) => void
};

export const BoundaryConditionComponent = ({
    bc,
    removeBoundaryCondition,
    updateBoundaryCondition,
    isSelected,
    addSelected,
    clearSelected,
    isHilighted,
    addHilighted,
    clearHilighted,
    startBcGeometrySelection }: BoundaryConditionComponentProps) => {

    const [borderColor, setBorderColor] = useState<string | undefined>(undefined);
    const [borderWidth, setBorderWidth] = useState<string | undefined>(undefined);

    useEffect(() => {
        if (isSelected) {
            setBorderColor("green.200");
            setBorderWidth("2px");
        }
        else if (isHilighted) {
            setBorderColor("blue.200");
            setBorderWidth("2px");
        } else {
            setBorderColor(undefined);
            setBorderWidth(undefined);
        }
    }, [isSelected, isHilighted]);

    const toggleComponent = (whichComponent: "u" | "v") => {
        const {kind} = bc.props;
        switch(whichComponent){
            case "u":
                kind.data.u = kind.data.u === undefined ? 0 : undefined;
                break;
            case "v":
                kind.data.v = kind.data.v === undefined ? 0 : undefined;
                break;
        }
        updateBoundaryCondition(bc, {kind});
    };

    return (
        <Box
            width="100%"
            borderColor={borderColor}
            borderWidth={borderWidth}
            borderRadius="5px"
            onMouseEnter={() => addHilighted(bc.id_)}
            onMouseLeave={() => clearHilighted()}
        >
            <HStack width="100%">
                <HStack flex="1">
                    <Badge flex="0.2"><Center>{bc.id_.slice(0, 5)}</Center></Badge>
                    {
                        startBcGeometrySelection !== undefined && <Badge min-height="100%" cursor="pointer" flex="1.0" onClick={() => {startBcGeometrySelection(bc.id_);}}><Center>{bc.geometry_ === "" ? "Click to select" : bc.geometry_.slice(0,5)}</Center></Badge>
                    }
                    <RadioGroup flex="2.0" value={bc.kind_.type} onChange={(val: string) => updateBoundaryCondition(bc, { kind: { ...bc.kind_, type: val === "fixed" ? "fixed" : "force" } })}>
                        <VStack>
                            <Radio value='fixed'>Fixed</Radio>
                            <Radio value='force'>Force</Radio>
                        </VStack>
                    </RadioGroup>
                    <VStack flex="2">
                        <HStack>
                            <Checkbox flex="0.2" isChecked={bc.kind_.data.u !== undefined} onChange={() => {toggleComponent('u');} }>u</Checkbox>
                            <NumberInput flex="1.0"
                                value={bc.kind_.data.u}
                                onChange={(valString: string, valNumber: number) => {
                                    updateBoundaryCondition(bc, {kind: {...bc.kind_, data: {...bc.kind_.data, u: valNumber} }});
                                }}
                                isDisabled={bc.kind_.data.u === undefined}
                            >
                                <NumberInputField />
                                <NumberInputStepper>
                                    <NumberIncrementStepper />
                                    <NumberDecrementStepper />
                                </NumberInputStepper>
                            </NumberInput>
                        </HStack>
                        <HStack>
                            <Checkbox isChecked={bc.kind_.data.v !== undefined} onChange={() => {toggleComponent('v');}}>v</Checkbox>
                            <NumberInput flex="1.0"
                                value={bc.kind_.data.v}
                                onChange={(valString: string, valNumber: number) => {
                                    updateBoundaryCondition(bc, {kind: {...bc.kind_, data: {...bc.kind_.data, v: valNumber} }});
                                }}
                                isDisabled={bc.kind_.data.v === undefined}
                            >
                                <NumberInputField />
                                <NumberInputStepper>
                                    <NumberIncrementStepper />
                                    <NumberDecrementStepper />
                                </NumberInputStepper>
                            </NumberInput>
                        </HStack>
                    </VStack>
                </HStack>
                <Button flex="0.2" onClick={() => removeBoundaryCondition(bc)}>Remove</Button>
            </HStack>
        </Box>
   )
};