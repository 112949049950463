import { v4 as uuid } from "uuid";

export interface LoopProps{
    id?: string;
    edges: Array<string>
};

export class Loop
{
    private _id: string;
    _edges: Array<string>;

    constructor(props: LoopProps){
        if(props.id === undefined){
            this._id = uuid();
        } else {
            this._id = props.id;
        }
        this._edges = props.edges;
    }

    get id(): string{
        return this._id;
    }

    get props(): LoopProps{
        return {
            id: this._id,
            edges: this._edges
        };
    }
};