import { Box, Button, HStack, Tag } from "@chakra-ui/react";
import { BoundaryCondition } from "../model/boundarycondition";

export interface SimpleBoundaryConditionProps{
    bc: BoundaryCondition,
    removeBoundaryCondition: (arg0: BoundaryCondition) => void
}

export const SimpleBoundaryCondition = ({
            bc,
            removeBoundaryCondition
}: SimpleBoundaryConditionProps) => {
    return (
        <Box>
            <HStack>
                <Tag>{bc.kind_.type === "fixed" ? "Fixed" : "Force"}</Tag>
                <Tag>u={bc.kind_.data.u}</Tag>
                <Tag>v={bc.kind_.data.v}</Tag>
                <Button onClick={() => removeBoundaryCondition(bc)}>Remove</Button>
            </HStack>
        </Box>
    );
}