import { useState, useEffect } from 'react';

export type DeletableType =
 { delete: () => void } | null

export function useDeletableState<T extends DeletableType>(initialValue: T){
    const [deletableState, setDeletableState] = useState<T>(initialValue);

    useEffect(() => {
        return () => {
            if(deletableState != null)
            {
                deletableState.delete();
            }
        };
    },[deletableState]);

    return [deletableState, setDeletableState] as const;
}

