import { Button, HStack, VStack, Text } from "@chakra-ui/react";
import { Edge, EdgeProps } from "../model/edge"
import { Node } from "../model/node";
import { EdgeComponent } from "./Edge";

export interface EdgeListProps{
    nodes: Array<Node>,
    edges: Array<Edge>,
    addEdge: (e: Edge) => void,
    removeEdge: (e: Edge) => void,
    updateEdge: (e: Edge, p: EdgeProps) => void
    selected: Array<string>,
    addSelected: (id: string) => void,
    clearSelected: () => void,
    hilighted: Array<string>,
    addHilighted: (id: string) => void,
    clearHilighted: () => void,
};

export const EdgeList = ({nodes,
                          edges,
                          addEdge,
                          removeEdge,
                          updateEdge,
                          selected,
                          addSelected,
                          clearSelected,
                          hilighted,
                          addHilighted,
                          clearHilighted
                        } : EdgeListProps) => {

    const handleAddEdge = () => {
        const start = nodes.at(0)?.id;
        const end = nodes.at(1)?.id;
        if(!start || !end) return;
        addEdge(new Edge({start, end, geometry: {type: "straight", data: []}}));
    };

    return (
        <VStack>
            {
                edges.map((e: Edge, index: number) => {
                    const isSelected = selected.find((id: string) => id === e.id) ? true : false;
                    const isHilighted = hilighted.find((id: string) => id === e.id) ? true : false;
                    return (
                        <EdgeComponent
                            key={index}
                            nodes={nodes}
                            edge={e}
                            addEdge={addEdge}
                            removeEdge={removeEdge}
                            updateEdge={updateEdge}
                            isSelected={isSelected}
                            clearSelected={clearSelected}
                            addSelected={addSelected}
                            isHilighted={isHilighted}
                            addHilighted={addHilighted}
                            clearHilighted={clearHilighted}
                        />
                    )
                })
            }
            {/*<Button width="100%" onClick={() => handleAddEdge()} isDisabled={nodes.length < 2}>Add edge</Button>*/}
        </VStack>
    );
};