import { Button, HStack, Tag, Text, VStack } from "@chakra-ui/react";
import { Node, NodeProps} from "../model/node";
import { Node as NodeComponent } from "./Node";


export interface NodeListProps{
    nodes: Array<Node>
    addNode: (n: Node) => void
    removeNode: (n: Node) => void
    updateNode: (n: Node, p: NodeProps) => void,
    selected: Array<string>,
    addSelected: (id: string) => void,
    clearSelected: () => void,
    hilighted: Array<string>,
    addHilighted: (id: string) => void,
    clearHilighted: () => void,
};

export const NodeList = ({nodes,
                          addNode,
                          removeNode,
                          updateNode,
                          selected,
                          addSelected,
                          clearSelected,
                          hilighted,
                          addHilighted,
                          clearHilighted,
                        } : NodeListProps) => {
    return (
        <VStack>
            {
                nodes.map((n: Node, index: number) => {
                    const isSelected = selected.find((id: string) => id === n.id) ? true : false;
                    const isHilighted = hilighted.find((id: string) => id === n.id) ? true : false;
                    return <NodeComponent 
                            key={index}
                            node={n}
                            remove={removeNode}
                            update={updateNode}
                            isSelected={isSelected}
                            clearSelected={clearSelected}
                            addSelected={addSelected}
                            isHilighted={isHilighted}
                            addHilighted={addHilighted}
                            clearHilighted={clearHilighted}
                            />;
                })
            }
            {/*<Button width="100%" onClick={()=>addNode(new Node({}))}>Add node</Button>*/}
        </VStack>
    );
}