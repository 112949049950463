import { v4 as uuid} from 'uuid';

export interface Point{
    x: number;
    y: number;
};

export interface NodeProps{
    coordinates?: Point;
    id?: string;
    isSelected?: boolean;
    isHilighted?: boolean;
};

export class Node{
    _id: string;
    _coordinates: Point;
    constructor(props: NodeProps){
        if(props.id !== undefined){
            this._id = props.id;
        } else {
            this._id = uuid();
        }

        if(props.coordinates !== undefined){
            this._coordinates = props.coordinates;
        } else {
            this._coordinates = {x: 0, y: 0};
        }

    }

    get coordinates(): Point{
        return this._coordinates;
    }

    set coordinates(coordinates: Point){
        this._coordinates = coordinates;
    }

    get id(): string {
        return this._id;
    }

    get props(): NodeProps{
        return {
            coordinates: this._coordinates,
            id: this._id,
        };
    }
}