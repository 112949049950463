import { useEffect, useState } from "react"
import { Node as GeometricNode, NodeProps as GeometricNodeProps, Point } from "../model/node"
import { Badge, Box, Button, Center, HStack, NumberDecrementStepper, NumberIncrementStepper, NumberInput, NumberInputField, NumberInputStepper, Text } from "@chakra-ui/react"

export interface NodeProps{
    node: GeometricNode
    remove: (n: GeometricNode) => void
    update: (n: GeometricNode, p: GeometricNodeProps) => void
    isSelected: boolean,
    addSelected: (id: string) => void
    clearSelected: () => void
    isHilighted: boolean
    addHilighted: (id: string) => void
    clearHilighted: () => void
};

export const Node = ({node,
                     remove,
                     isSelected,
                     addSelected,
                     clearSelected,
                     isHilighted,
                     addHilighted,
                     clearHilighted,
                     update}: NodeProps) => {

    const [borderColor, setBorderColor] = useState<string | undefined>(undefined);
    const [borderWidth, setBorderWidth] = useState<string | undefined>(undefined);

    useEffect(() => {
        if(isSelected){
            setBorderColor("green.200");
            setBorderWidth("2px");
        }
        else if(isHilighted){
            setBorderColor("blue.200");
            setBorderWidth("2px");
        } else {
            setBorderColor(undefined);
            setBorderWidth(undefined);
        }
    }, [isSelected, isHilighted]);

    return (
        <Box
         width="100%"
         borderColor={borderColor}
         borderWidth={borderWidth}
         borderRadius="5px"
         onMouseEnter={() => addHilighted(node.id)}
         onMouseLeave={() => clearHilighted()}
        >
            <HStack width="100%">
                <HStack flex="4">
                    <Badge flex="0.2"><Center>{node._id.slice(0,5)}</Center></Badge>
                    <NumberInput flex="0.4"
                        value={node.coordinates.x}
                        onChange={(valString: string, valNumber: number) => {
                            update(node, { coordinates: { ...node.coordinates, x: valNumber } })
                        }}
                        onFocus={() => addSelected(node.id)}
                        onBlur={() => clearSelected()}
                    >
                        <NumberInputField />
                        <NumberInputStepper>
                            <NumberIncrementStepper />
                            <NumberDecrementStepper />
                        </NumberInputStepper>
                    </NumberInput>
                    <NumberInput flex="0.4"
                        value={node.coordinates.y}
                        onChange={(valString: string, valNumber: number) => {
                            update(node, { coordinates: { ...node.coordinates, y: valNumber } })
                        }}
                        onFocus={() => addSelected(node.id)}
                        onBlur={() => clearSelected()}
                    >
                        <NumberInputField />
                        <NumberInputStepper>
                            <NumberIncrementStepper />
                            <NumberDecrementStepper />
                        </NumberInputStepper>
                    </NumberInput>
                </HStack>
            <Button flex="1" onClick={() => remove(node)}>Remove</Button>
        </HStack>
        </Box>
    );
}