import { useCallback, useEffect, useRef, useState } from "react";
import * as Trace from '../pkg/fem/emtrace'
import * as Emtrace from '../pkg/fem/emtrace-types'
import { BoundaryCondition } from "../model/boundarycondition";
import { Node, Point } from "../model/node";
import { Edge } from "../model/edge";

const isAppliedOnEdge = (bc: BoundaryCondition, edges: Array<Edge>) => {
    return edges.find((e: Edge) => bc.geometry_ === e.id) !== undefined;
}

export const useTraceLib = () => {

    const [traceLibReady, setReady] = useState<boolean>(false);

    const module = useRef<Emtrace.MainModule | null>(null);

    useEffect(() => {
        Trace.default().then((trace: Emtrace.MainModule) => {
            module.current = trace;
            setReady(true);
        });
    }, []);

    const runPlaneStressAnalysis = useCallback( (
        meshJSON: string,
        boundaryConditions: Array<BoundaryCondition>,
        uuidToNodes: Map<string, Array<number>>,
        nodes: Array<Node>,
        edges: Array<Edge>) => {
        if(module.current === null) return null;
        const analysis = new module.current.PlaneStressAnalysis(meshJSON);
        analysis.turnOnLogger();
        analysis.setMaterialParameters(1.0, 0.3);

        boundaryConditions.forEach((bc: BoundaryCondition) => {
            const nodes = uuidToNodes.get(bc.geometry_);
            if (bc.kind_.type === "fixed") {
                nodes?.forEach((nodeId: number) => {
                    if (bc.kind_.data.u !== undefined) {
                        analysis.fixNode(nodeId, 0, bc.kind_.data.u);
                    }
                    if (bc.kind_.data.v !== undefined) {
                        analysis.fixNode(nodeId, 1, bc.kind_.data.v);
                    }
                });
            } else {
                if(isAppliedOnEdge(bc, edges)){
                    const edge = edges.find((e: Edge) => e.id === bc.geometry_);
                    if(edge === undefined) return;

                    const startNodeId = edge.start;
                    const endNodeId = edge.end;

                    const startNodeIdInMesh = uuidToNodes.get(startNodeId)?.at(0);
                    const endNodeIdInMesh = uuidToNodes.get(endNodeId)?.at(0);

                    const allNodes = uuidToNodes.get(bc.geometry_);
                    if(allNodes === undefined) return;
                    if(bc.kind_.data.u !== undefined){
                        const contributionPerNode = bc.kind_.data.u / (allNodes.length - 1);
                        allNodes.forEach((nodeId: number) => {
                            if(nodeId === startNodeIdInMesh || nodeId === endNodeIdInMesh){
                                analysis.addNodalForce(nodeId, contributionPerNode / 2. , 0.0);
                            } else {
                                analysis.addNodalForce(nodeId, contributionPerNode, 0.0);
                            }
                        })
                    }
                    if(bc.kind_.data.v !== undefined){
                        const contributionPerNode = bc.kind_.data.v / (allNodes.length - 1);
                        allNodes.forEach((nodeId: number) => {
                            if(nodeId === startNodeIdInMesh || nodeId === endNodeIdInMesh){
                                analysis.addNodalForce(nodeId, 0.0, contributionPerNode / 2. );
                            } else {
                                analysis.addNodalForce(nodeId, 0.0, contributionPerNode);
                            }
                        })
                    }
                } else { // B.C. is applied on Node
                    const nodeId = uuidToNodes.get(bc.geometry_)?.at(0);
                    if(nodeId === undefined) return;
                    if(bc.kind_.data.u !== undefined) analysis.addNodalForce(nodeId, bc.kind_.data.u, 0);
                    if(bc.kind_.data.v !== undefined) analysis.addNodalForce(nodeId, 0.0, bc.kind_.data.v);
                }
            }
        });
        analysis.solve();
        return analysis;
    },[module]);

    const computeTrajectories = useCallback( (simulationObject: Emtrace.PlaneStressAnalysis, seeds: Array<Point>, stepSize: number, maxSteps: number ) => {
        if(module.current === null) return null;
        if(seeds.length === 0) return "";
        simulationObject.clearTraces();

        const seedVector = new module.current.Point2DVector();
        seeds.forEach((seed: Point) => {
            if(module.current === null) return;
            const wasmSeed = new module.current.Point2D();
            wasmSeed.x = seed.x;
            wasmSeed.y = seed.y;
            seedVector.push_back(wasmSeed);
            wasmSeed.delete();
        });

        simulationObject.addTraces(seedVector, {step_size: stepSize, max_steps: maxSteps});

        const result = simulationObject.svgTrajectories();
        seedVector.delete();
        return result;
    }, [module]);

    return [traceLibReady, runPlaneStressAnalysis, computeTrajectories] as const;
};