import { Button, VStack } from "@chakra-ui/react";
import { Loop as GeometricLoop } from "../model/loop"
import { Loop } from "./Loop";


export interface LoopListProps{
    loops: Array<GeometricLoop>
    removeLoop: (l: GeometricLoop) => void
    addSelected: (id: string) => void,
    clearSelected: () => void,
    hilighted: Array<string>,
    addHilighted: (id: string) => void,
    clearHilighted: () => void,
};

export const LoopList = ({
                        loops,
                        removeLoop,
                        addSelected,
                        clearSelected,
                        addHilighted,
                        clearHilighted} : LoopListProps) => {
        return( 
        <VStack>
        {
            loops.map((l: GeometricLoop, index: number) => {
                return (
                     <Loop
                        key={index}
                        loop={l}
                        remove={removeLoop}
                        addSelected={addSelected}
                        clearSelected={clearSelected}
                        addHilighted={addHilighted}
                        clearHilighted={clearHilighted}/>
                    )
                })
        }
        {/*<Button width="100%">Add loop</Button>*/}
        </VStack>
        );
};
