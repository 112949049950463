import { Box, Text, theme, useBreakpointValue } from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { Model, InteractionMode, getNodeOrEdge } from "../App";
import { Node, NodeProps } from "../model/node";
import { Edge } from "../model/edge";
import { render } from "@testing-library/react";
import { start } from "repl";
import { BoundaryCondition, BoundaryConditionProps } from "../model/boundarycondition";

// Arrow sources
//https://thenounproject.com/icon/up-89589/
//https://upload.wikimedia.org/wikipedia/commons/8/85/Arrow_top_svg.svg

const MIN_DISTANCE_FOR_FIXED_BC = 20;

interface Dimensions{
    width: number,
    height: number
};

interface Point{
    x: number,
    y: number
};


export interface CanvasProps{
    model: Model;
    updateNode: (n: Node, props: NodeProps) => void
    addNode: (n: Node) => void
    interactionMode: InteractionMode,
    addSelected: (id: string) => void
    clearSelected: () => void
    selected: Array<string>
    addHilighted: (id: string) => void
    clearHilighted: () => void
    hilighted: Array<string>
    meshSVG: string
    svgTrajectories: string
    addSeedPoint: (p: Point) => void
    createInteractiveBoundaryCondition: (geometryId: string) => void
    updateBoundaryCondition: (bc: BoundaryCondition, props: BoundaryConditionProps) => void
}

export const Canvas = ({model,
                        updateNode,
                        addNode,
                        interactionMode,
                        addSelected,
                        clearSelected,
                        selected,
                        addHilighted,
                        clearHilighted,
                        hilighted,
                        meshSVG,
                        svgTrajectories,
                        addSeedPoint,
                        createInteractiveBoundaryCondition,
                        updateBoundaryCondition} : CanvasProps) => {
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const canvasContainerRef = useRef<HTMLDivElement>(null);

    const [dimensions, setDimensions] = useState<Dimensions>({width: 300, height: 150});
    const [dragStart, setDragStart] = useState<Point | null>(null);
    const canvasScaleFactor = useBreakpointValue<number>({base: 0.5, md: 1.0, fallback: 1.0});

    useEffect(() => {
        const resizeObserver = new ResizeObserver((entries) => {
            for(let entry of entries){
                if(entry.target === canvasContainerRef.current){
                    const {width, height} = entry.contentRect;
                    setDimensions({width, height});
                }
            }
        });

        if(canvasContainerRef.current){
            resizeObserver.observe(canvasContainerRef.current);
        }

        if(canvasRef.current == null) return;
        const context = canvasRef.current.getContext("2d");
        if(context === null) return;

        context.fillStyle = 'mintcream';
        context.fillRect(0, 0, canvasRef.current.width, canvasRef.current.height);
        return () => {
            resizeObserver.disconnect();
        };
    },[]);

    useEffect(() => {
        if(canvasRef.current === null) return;
        canvasRef.current.width = dimensions.width;
        canvasRef.current.height = dimensions.height;
        const context = canvasRef.current.getContext("2d");
        if(context === null) return;
        context.resetTransform();
        context.translate(dimensions.width / 2, dimensions.height / 2);
        context.scale(1, -1);
        context.scale(canvasScaleFactor ?? 1, canvasScaleFactor ?? 1);
    }, [dimensions, canvasScaleFactor])

    useEffect(() => {
        if(canvasRef.current == null) return;
        const context = canvasRef.current.getContext("2d");
        if(context === null) return;

        context.fillStyle = 'mintcream';
        context.fillRect(
            -dimensions.width / 2 / (canvasScaleFactor ?? 1),
            -dimensions.height / 2 / (canvasScaleFactor ?? 1),
             dimensions.width / (canvasScaleFactor ?? 1),
             dimensions.height/ (canvasScaleFactor ?? 1));
        context.save();
        renderMesh(context, meshSVG);
        renderNodes(context, model.nodes, selected, hilighted);
        renderEdges(context, model.nodes, model.edges, selected, hilighted);
        renderTrajectories(context, svgTrajectories);
        renderBoundaryConditions(context, model.boundaryConditions, model.nodes, model.edges);
        context.restore();

    }, [model, dimensions, selected, hilighted, meshSVG, svgTrajectories]);

    const handleMouseMove = (e: React.MouseEvent) => {
        if(canvasRef.current == null) return;
        const context = canvasRef.current.getContext("2d");
        if(context === null) return;

        const T = context.getTransform();
        const boundingRect = canvasRef.current.getBoundingClientRect();
        let x = e.clientX - boundingRect.left;
        let y = e.clientY - boundingRect.top;
        const domPoint = new DOMPoint(x, y);
        const pointInWorldCoordinates = T.inverse().transformPoint(domPoint);
        x = pointInWorldCoordinates.x;
        y = pointInWorldCoordinates.y;

        if(interactionMode === InteractionMode.GameBoundaryCondition && selected.length !== 0 && dragStart !== null){
            // Get the selected boundary condition
            const bc = model.boundaryConditions.find((bc: BoundaryCondition) => bc.id_ === selected[0]);
            if(bc === undefined) return;

            // Get the geometric support that represents the boundary condition
            const geometricSupport = getNodeOrEdge(bc.geometry_, model);

            if(geometricSupport.type === "none") return;

            const dx = x - dragStart.x;
            const dy = y - dragStart.y;
            let dist = Math.hypot(dx * dx + dy * dy);

            if(geometricSupport.type === "edge"){
                dist = getDistanceToEdge({x, y}, geometricSupport.data, model.nodes);
            }


            if(dist < MIN_DISTANCE_FOR_FIXED_BC){
                updateBoundaryCondition(bc, {...bc.props, kind: {type: "fixed", data: {u: 0, v: 0}}});
            } else {
                const angle = Math.atan2(dy, dx);
                let u = Math.round(Math.round(Math.cos(angle) / (Math.PI / 4.)) * Math.PI / 4.);
                let v = Math.round(Math.round(Math.sin(angle) / (Math.PI / 4.)) * Math.PI / 4.);
                updateBoundaryCondition(bc, {...bc.props, kind: {type: "force", data: {u, v}}});
            }
        } else {
            clearHilighted();

            let hasHilighted = false
            for(const n of model.nodes){
                const distance = Math.sqrt((x - n.coordinates.x) * (x - n.coordinates.x) + (y - n.coordinates.y) * (y - n.coordinates.y));
                
                if(distance < 10){
                    hasHilighted = true;
                    addHilighted(n.id);
                } 
            }
            if(hasHilighted) return;
            for(const e of model.edges){
                const distance = getDistanceToEdge({x, y}, e, model.nodes);
                if(distance < 10){
                    hasHilighted = true
                    addHilighted(e.id);
                }
            }
        }
    };

    const handleMouseClick = (e: React.MouseEvent) => {
        if(canvasRef.current == null) return;
        const context = canvasRef.current.getContext("2d");
        if(context === null) return;

        const T = context.getTransform();
        const boundingRect = canvasRef.current.getBoundingClientRect();
        let x = e.clientX - boundingRect.left;
        let y = e.clientY - boundingRect.top;
        const domPoint = new DOMPoint(x, y);
        const pointInWorldCoordinates = T.inverse().transformPoint(domPoint);
        x = pointInWorldCoordinates.x;
        y = pointInWorldCoordinates.y;

        if(interactionMode === InteractionMode.Select ||
           interactionMode === InteractionMode.EdgeDrawing ||
           interactionMode === InteractionMode.ArcDrawing ||
           interactionMode === InteractionMode.LoopCreation ||
           interactionMode === InteractionMode.SelectBCGeometry
        ) {
            for(const n of model.nodes){
                const distance = Math.sqrt((x - n.coordinates.x) * (x - n.coordinates.x) + (y - n.coordinates.y) * (y - n.coordinates.y));
                if(distance < 10){
                    addSelected(n.id);
                    return;
                }
            }

            for(const e of model.edges){
                const distance = getDistanceToEdge({x, y}, e, model.nodes);
                if(distance < 10){
                    addSelected(e.id);
                    return;
                }
            }
        
            clearSelected();

        } else if(interactionMode === InteractionMode.NodeDrawing){
            addNode(new Node({coordinates: {x: Math.floor(x), y: Math.floor(y)}}));
            return;
        } else if(interactionMode === InteractionMode.Trace){
            addSeedPoint({x, y})
        }
    };

    const handleMouseDown = (e: React.MouseEvent) => {
        if(canvasRef.current == null) return;
        const context = canvasRef.current.getContext("2d");
        if(context === null) return;
        const T = context.getTransform();
        const boundingRect = canvasRef.current.getBoundingClientRect();
        let x = e.clientX - boundingRect.left;
        let y = e.clientY - boundingRect.top;
        const domPoint = new DOMPoint(x, y);
        const pointInWorldCoordinates = T.inverse().transformPoint(domPoint);
        x = pointInWorldCoordinates.x;
        y = pointInWorldCoordinates.y;

        if(interactionMode === InteractionMode.GameBoundaryCondition){
            for(const n of model.nodes){
                const distance = Math.sqrt((x - n.coordinates.x) * (x - n.coordinates.x) + (y - n.coordinates.y) * (y - n.coordinates.y));
                if(distance < 10){
                    setDragStart({x, y});
                    createInteractiveBoundaryCondition(n.id);
                    return;
                }
            }

            for(const e of model.edges){
                const distance = getDistanceToEdge({x, y}, e, model.nodes);
                if(distance < 10){
                    setDragStart({x, y});
                    createInteractiveBoundaryCondition(e.id);
                    return;
                }
            }

            clearSelected();
        }
    }
    
    const handleMouseUp = (e: React.MouseEvent) => {
        if(canvasRef.current == null) return;
        const context = canvasRef.current.getContext("2d");
        if(context === null) return;
        const T = context.getTransform();
        const boundingRect = canvasRef.current.getBoundingClientRect();
        let x = e.clientX - boundingRect.left;
        let y = e.clientY - boundingRect.top;
        const domPoint = new DOMPoint(x, y);
        const pointInWorldCoordinates = T.inverse().transformPoint(domPoint);
        x = pointInWorldCoordinates.x;
        y = pointInWorldCoordinates.y;

        if(interactionMode === InteractionMode.GameBoundaryCondition){
            setDragStart(null);
        }
    }

    const handleMouseLeave = (e: React.MouseEvent) => {
        if(interactionMode === InteractionMode.GameBoundaryCondition){
            setDragStart(null);
        }
    }

    return(
        <Box flex="1" ref={canvasContainerRef} width="100%" minH="0">
            <canvas
             style={{touchAction: "pinch-zoom"}}
             ref={canvasRef}
             onPointerMove={handleMouseMove}
             onClick={handleMouseClick}
             onPointerDown={handleMouseDown}
             onPointerUp={handleMouseUp}
             onPointerLeave={handleMouseLeave}
            >
             </canvas>
        </Box>
    );
};

const renderNodes = (ctx: CanvasRenderingContext2D, nodes: Array<Node>, selected: Array<string>, hilighted: Array<string>) => {
        nodes.forEach((n: Node) => {
            ctx.save();
            ctx.beginPath();
            ctx.fillStyle = 'black';
            if(selected.find((id: string) => id === n.id)){
                ctx.fillStyle = theme.colors.green[200];
            } else if(hilighted.find((id: string) => n.id === id)){
                ctx.fillStyle = theme.colors.blue[200];
            }
            ctx.arc(n.coordinates.x, n.coordinates.y, 10.0, 0.0, 2 * Math.PI, false);
            ctx.fill();
            ctx.restore();
        });
}

const renderEdges = (ctx: CanvasRenderingContext2D, nodes: Array<Node>, edges: Array<Edge>, selected: Array<string>, hilighted: Array<string>) => {
    edges.forEach((e: Edge) => {
        ctx.save();
        ctx.beginPath();
        ctx.lineWidth = 1.0;
        if(selected.find((id: string) => id === e.id)){
            ctx.lineWidth = 5.0;
            ctx.strokeStyle = theme.colors.green[200];
        } else if(hilighted.find((id: string) => id === e.id)) {
            ctx.lineWidth = 5.0;
            ctx.strokeStyle = theme.colors.blue[200];
        } else {
            ctx.lineWidth = 3.0;
            ctx.strokeStyle = 'black';
        }
        const start = nodes.find((n: Node) => n.id === e.start);
        const end = nodes.find((n: Node) => n.id === e.end);
        if(start === undefined || end === undefined) return;
        if(e._geometry.type === "arc"){
            const centerId = e._geometry.data.center;
            const center = nodes.find((n: Node) => n.id === centerId);
            if(center === undefined){
                ctx.restore();
                return;
            }
            const {radius, startAngle, endAngle, counterClockwise} = computeArcParameters(start.coordinates, end.coordinates, center.coordinates);
            ctx.arc(center.coordinates.x, center.coordinates.y, radius, startAngle, endAngle, counterClockwise);
        } else {
            ctx.moveTo(start.coordinates.x, start.coordinates.y);
            ctx.lineTo(end.coordinates.x, end.coordinates.y);
        }
        ctx.stroke();
        ctx.restore();
    });
}

const renderMesh = (context: CanvasRenderingContext2D, meshSVG: string) => {
    context.beginPath();
    context.strokeStyle = "wheat";
    const path = new Path2D(meshSVG);
    context.stroke(path);
}

const renderTrajectories = (context: CanvasRenderingContext2D, svgTrajectories: string) => {
    context.save();
    context.beginPath();
    if (svgTrajectories !== "") {
        const coloredTrajectories = new Map<string, string>(Object.entries(JSON.parse(svgTrajectories)));
        coloredTrajectories.forEach((path: string, color: string) => {
            const trajectoriesPath = new Path2D(path);
            context.strokeStyle = color;
            context.lineWidth = 3;
            context.stroke(trajectoriesPath);
        });
    }
    context.restore();
}

export type GeometricSupport = 
    {type: "node", value: Node} | 
    {type: "edge", value: Edge} | 
    {type: "none"};

const geometricSupport = (bc: BoundaryCondition, nodes: Array<Node>, edges: Array<Edge>) : GeometricSupport => {
    const id = bc.geometry_;
    const node = nodes.find((n: Node) => n._id === id);
    if(node !== undefined){
        return {type: "node", value: node};
    }

    const edge = edges.find((e: Edge) => e._id === id);
    if(edge !== undefined){
        return {type: "edge", value: edge};
    }

    return {type: "none"};
};

const renderBoundaryConditions = (context: CanvasRenderingContext2D, boundaryConditions: Array<BoundaryCondition>, nodes: Array<Node>, edges: Array<Edge>) => {
    context.save();
    boundaryConditions.forEach((bc: BoundaryCondition) => {
        // Get the geometry support of the boundary condition
        const support = geometricSupport(bc, nodes, edges);
        const points = new Array<Point>();
        switch(support.type){
            case "node":
                points.push({x: support.value.coordinates.x, y: support.value.coordinates.y})
                break;
            case "edge":
                const spacing = 50;
                const startNode = nodes.find((n: Node) => support.value._start === n._id);
                const endNode = nodes.find((n: Node) => support.value._end === n._id);
                if(startNode === undefined || endNode === undefined) return;
                if (support.value._geometry.type === "straight") {
                    const length = Math.hypot(endNode.coordinates.x - startNode.coordinates.x, endNode.coordinates.y - startNode.coordinates.y);
                    let numberOfArrows = Math.floor(length / spacing);
                    numberOfArrows += 2;
                    const delta = 1.0 / (numberOfArrows - 1);
                    for (let i = 0; i < numberOfArrows; i++) {
                        const node = {
                            x: startNode.coordinates.x + i * delta * (endNode.coordinates.x - startNode.coordinates.x),
                            y: startNode.coordinates.y + i * delta * (endNode.coordinates.y - startNode.coordinates.y)
                        };
                        points.push(node);
                    }
                } else if(support.value._geometry.type === "arc") {
                    const centerId = support.value._geometry.data.center;
                    const center = nodes.find((n: Node) => centerId === n.id);
                    if(center === undefined) return;
                    const radius = Math.hypot(startNode.coordinates.x - center._coordinates.x, startNode.coordinates.y - center.coordinates.y);
                    let startAngle = Math.atan2(startNode.coordinates.y - center.coordinates.y, startNode.coordinates.x - center.coordinates.x);
                    let endAngle = Math.atan2(endNode.coordinates.y - center.coordinates.y, endNode.coordinates.x - center.coordinates.x);
                    if(startAngle * endAngle < 0){
                        endAngle -= 2 * Math.PI;
                    }
                    const arcLength = radius * Math.abs(endAngle - startAngle);
                    let numberOfArrows = Math.floor(arcLength / spacing);
                    numberOfArrows += 2;
                    const delta = 1. / (numberOfArrows - 1);
                    for(let i = 0; i < numberOfArrows; ++i){
                        const t = i * delta;
                        const angle = startAngle + t * (endAngle - startAngle);
                        const x = center.coordinates.x + radius * Math.cos(angle);
                        const y = center.coordinates.y + radius * Math.sin(angle);
                        points.push({x, y});
                    }
                }
                break;
            case "none":
                console.log("Unknown BC");
        }
        renderPointWiseBoundaryCondition(context, bc, points);
    });
    context.restore();
}

const renderPointWiseBoundaryCondition = (context: CanvasRenderingContext2D, bc: BoundaryCondition, points: Array<Point>) => {
    context.beginPath();
    let arrowSVGSTring = "m 87.563214,12.459059 c -4.426201,4.917612 -21.854358,24.280719 -22.130995,24.58807 -4.149561,4.76394 -4.149561,12.140361 0,16.9043 4.149561,4.763939 11.065495,4.763939 15.215054,0 0.276644,-0.307351 40.665697,-45.0269051 40.665697,-45.0269051 4.28788,-4.763939 4.28788,-12.4477118 0,-16.9042999 0,0 -39.697463,-44.258533 -40.665697,-45.180587 -4.149559,-4.610263 -10.927175,-4.610263 -15.215054,0 -4.149561,4.76394 -4.149561,12.447712 0,17.057976 0.829913,0.922054 22.269313,24.588075 22.269313,24.588075 H 10.796336 c -5.9477045,0 -10.788859,5.3786413 -10.788859,11.98668592 0,6.60804448 4.8411545,11.98668508 10.788859,11.98668508 z";
    if(bc.kind_.type === "fixed"){
        arrowSVGSTring = "M -33.849609 -67.554688 L -48.361328 -53.044922 L 0.0078125 -4.6777344 L 48.375 -53.044922 L 33.865234 -67.554688 L 0.0078125 -33.697266 L -33.849609 -67.554688 z M 0.0078125 5.5839844 L -48.361328 53.951172 L -33.849609 68.460938 L 0.0078125 34.603516 L 33.865234 68.460938 L 48.375 53.951172 L 0.0078125 5.5839844 z ";
    }
    const arrowPath = new Path2D(arrowSVGSTring);
    const angle = Math.atan2(bc.kind_.data.v === undefined ? 0 : bc.kind_.data.v, bc.kind_.data.u === undefined ? 0 : bc.kind_.data.u);
    points.forEach((node: Point) => {
        context.save();
        context.translate(node.x, node.y);
        context.scale(0.25, 0.25);
        context.fillStyle = "#3aaba6";
        if(bc.kind_.type === "fixed"){
            if(bc.kind_.data.v !== undefined){
                context.fill(arrowPath);
            }
            if(bc.kind_.data.u !== undefined){
                context.save();
                context.rotate(Math.PI / 2);
                context.fill(arrowPath);
                context.restore();
            }
        } else {
            context.rotate(angle);
            context.fill(arrowPath);
        }
        context.restore();
    });
}


const getDistanceToEdge = (point: Point, edge: Edge, nodes: Array<Node>) : number => {
        const p1 = nodes.find((n: Node) => n.id === edge.start)?.coordinates;
        const p2 = nodes.find((n: Node) => n.id === edge.end)?.coordinates;

        if(p1 === undefined || p2 === undefined) return Math.max();

        const {x, y} = {...point};

        if(edge._geometry.type === "arc"){
            const centerId = edge._geometry.data.center;
            const center = nodes.find((n: Node) => n.id === centerId);
            if(center === undefined) return Math.max();
            return distanceToArc(point, p1, p2, center.coordinates);
        }

        let dx = p2.x - p1.x;
        let dy = p2.y - p1.y;

        if (Math.abs(dx) <= 1E-6 && Math.abs(dy) <= 1E-6) {
            return Math.sqrt((p1.x - x) * (p1.x - x) + (p1.y - y) * (p1.y - y));
        }

        const t = ((x - p1.x) * dx + (y - p1.y) * dy) / (dx * dx + dy * dy);

        if (t < 0) {
            dx = x - p1.x;
            dy = y - p1.y;
        } else if (t > 1) {
            dx = x - p2.x;
            dy = y - p2.y;
        } else {
            const closestPoint = { x: p1.x + t * dx, y: p1.y + t * dy };
            dx = x - closestPoint.x;
            dy = y - closestPoint.y;
        }

        return Math.sqrt(dx * dx + dy * dy);
    }

const normalizeAngle = (angle: number) => {
    while(angle < 0) angle += 2 * Math.PI;
    while(angle >= 2 * Math.PI) angle -= 2 * Math.PI;
    return angle;
}

const distanceToArc = (P: Point, A: Point, B: Point, C: Point) => {
    const arcParameters = computeArcParameters(A, B, C);
    const angleToPoint = Math.atan2((P.y - C.y), (P.x - C.x));

    const angle = normalizeAngle(angleToPoint);
    const start = normalizeAngle(arcParameters.startAngle);
    const end = normalizeAngle(arcParameters.endAngle);

    let isInArc = false;
    if(start < end){
        isInArc = start <= angle && angle <= end;
    } else {
        isInArc = start <= angle || angle <= end;
    }
    
    isInArc = arcParameters.counterClockwise ? !isInArc : isInArc;

    if(isInArc){
        const distanceToCenter = Math.sqrt((P.x - C.x) * (P.x - C.x) + (P.y - C.y) * (P.y - C.y));
        return Math.abs(distanceToCenter - arcParameters.radius);
    } else {
        const distanceToA = Math.sqrt((P.x - A.x) * (P.x - A.x) + (P.y - A.y) * (P.y - A.y));
        const distanceToB = Math.sqrt((P.x - B.x) * (P.x - B.x) + (P.y - B.y) * (P.y - B.y));
        return Math.min(distanceToA, distanceToB);
    }
};

const computeArcParameters = (A: Point, B: Point, C: Point) => {
    const radius = Math.sqrt((A.x - C.x) * (A.x - C.x) + (A.y - C.y) * (A.y - C.y));
    const startAngle = Math.atan2((A.y - C.y), (A.x - C.x));
    const endAngle = Math.atan2((B.y - C.y), (B.x - C.x));

    let angleDifference = endAngle - startAngle;
    if(angleDifference < 0){
        angleDifference += 2 * Math.PI;
    }

    const counterClockwise = angleDifference > Math.PI;

    return {
        radius,
        startAngle,
        endAngle,
        counterClockwise
    }
}