import {Edge as GeometricEdge, EdgeProps as GeometricEdgeProps} from '../model/edge';
import { Node } from '../model/node';
import { Badge, Box, Button, Center, HStack, Text } from '@chakra-ui/react';
import { useEffect, useState } from 'react';

export interface EdgeProps {
    edge: GeometricEdge,
    nodes: Array<Node>,
    addEdge: (e: GeometricEdge) => void,
    removeEdge: (e: GeometricEdge) => void,
    updateEdge: (e: GeometricEdge, p: GeometricEdgeProps) => void
    isSelected: boolean,
    addSelected: (id: string) => void
    clearSelected: () => void
    isHilighted: boolean
    addHilighted: (id: string) => void
    clearHilighted: () => void
};

export const EdgeComponent = ({edge,
                               nodes,
                               addEdge,
                               removeEdge,
                               isSelected,
                               addSelected,
                               clearSelected,
                               isHilighted,
                               addHilighted,
                               clearHilighted,
                               updateEdge} : EdgeProps) => {

    const [borderColor, setBorderColor] = useState<string | undefined>(undefined);
    const [borderWidth, setBorderWidth] = useState<string | undefined>(undefined);

    useEffect(() => {
        if(isSelected){
            setBorderColor("green.200");
            setBorderWidth("2px");
        }
        else if(isHilighted){
            setBorderColor("blue.200");
            setBorderWidth("2px");
        } else {
            setBorderColor(undefined);
            setBorderWidth(undefined);
        }
    }, [isSelected, isHilighted]);

    return (
        <Box
         width="100%"
         borderColor={borderColor}
         borderWidth={borderWidth}
         borderRadius="5px"
         onMouseEnter={() => addHilighted(edge.id)}
         onMouseLeave={() => clearHilighted()}
         >
        <HStack width="100%">
            <HStack flex="1">
                <Badge flex="0.2"
                onMouseEnter={() => addHilighted(edge.id)}
                onMouseLeave={() => clearHilighted()}
                ><Center>{edge._id.slice(0,5)}</Center>
                </Badge>
                <Badge
                 size="l"
                 flex="1"
                 onMouseEnter={() => addHilighted(edge.start)}
                 onMouseLeave={() => clearHilighted()}
                 >
                    <Center>{edge.start.slice(0,5)}</Center>
                </Badge>
                <Badge
                 size="l"
                 flex="1"
                 onMouseEnter={() => addHilighted(edge.end)}
                 onMouseLeave={() => clearHilighted()}
                 >
                    <Center>{edge.end.slice(0,5)}</Center>
                 </Badge>
            </HStack>
            <Button flex="1" onClick={() => removeEdge(edge)}>Remove</Button>
        </HStack>
        </Box>
    )
}