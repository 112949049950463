import { BoundaryCondition, BoundaryConditionProps } from "./model/boundarycondition";
import { BoundaryConditionComponent } from "./components/BoundaryConditionComponent";
import { Button, HStack, Tag, Text, VStack } from "@chakra-ui/react";

export interface BoundaryConditionListProps {
    boundaryConditions: Array<BoundaryCondition>
    addBoundaryCondition: (arg0: BoundaryCondition) => void,
    removeBoundaryCondition: (arg0: BoundaryCondition) => void,
    updateBoundaryCondition: (arg0: BoundaryCondition, arg1: BoundaryConditionProps) => void
    selected: Array<string>,
    addSelected: (id: string) => void
    clearSelected: () => void
    hilighted: Array<string>,
    addHilighted: (id: string) => void
    clearHilighted: () => void
    startBcGeometrySelection: (id: string) => void
};

export const BoundaryConditionList = ({
    boundaryConditions,
    addBoundaryCondition,
    removeBoundaryCondition,
    updateBoundaryCondition,
    selected,
    addSelected,
    clearSelected,
    hilighted,
    addHilighted,
    clearHilighted,
    startBcGeometrySelection }: BoundaryConditionListProps) => {
    return (
        <VStack>
            {
                boundaryConditions.map((bc: BoundaryCondition, index: number) => {
                    const isSelected = selected.find((id: string) => id === bc.id_) ? true : false;
                    const isHilighted = hilighted.find((id: string) => id === bc.id_) ? true : false;
                    return <BoundaryConditionComponent 
                            key={index}
                            bc={bc}
                            removeBoundaryCondition={removeBoundaryCondition}
                            updateBoundaryCondition={updateBoundaryCondition}
                            isSelected={isSelected}
                            clearSelected={clearSelected}
                            addSelected={addSelected}
                            isHilighted={isHilighted}
                            addHilighted={addHilighted}
                            clearHilighted={clearHilighted}
                            startBcGeometrySelection={startBcGeometrySelection}
                            />;
                })
            }
            <Button width="100%" onClick={()=>{
                addBoundaryCondition(new BoundaryCondition({kind:{type:"fixed", data:{}}}))
                }}>Add Boundary Condition</Button>
        </VStack>

        );
};